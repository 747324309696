import * as React from 'react';
import { useState, useEffect } from 'react';
import { Admin, Resource, DataProvider } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';

import './App.css';

import authProvider from './authProvider';
import themeReducer from './themeReducer';
import { Login, Layout } from './layout';
import { Dashboard } from './dashboard';
import customRoutes from './routes';
import englishMessages from './i18n/pt';

import visitors from './visitors';
import orders from './orders';
import products from './products';
import invoices from './invoices';
import categories from './categories';
import categories_power from './categories_power';
import categories_power_range from './categories_power_range';
import enterprises from './enterprises';
import reviews from './reviews';
import global_irradiation from './global_irradiation';
import state_irradiation from './state_irradiation';
import calculator from './calculator';

// import dataProviderFactory from './dataProvider';
// import fakeServerFactory from './fakeServer';
import {
    FirebaseAuthProvider,
    FirebaseDataProvider,
} from 'react-admin-firebase';
import OrderListOrdered from './orders/OrderListOrdered';

let config: {
    storageBucket: string;
    apiKey: string;
    messagingSenderId: string;
    projectId: string;
    databaseURL: string;
    authDomain: string;
};
config = {
    apiKey: 'AIzaSyDLJFTL5VpfArZaRnUXgt6nt5NDio-q-b0',
    authDomain: 'comparasol.firebaseapp.com',
    databaseURL: 'https://comparasol-default-rtdb.firebaseio.com',
    projectId: 'comparasol',
    storageBucket: 'comparasol.appspot.com',
    messagingSenderId: '627407829390',
};
const options = {
    watch: ['posts', 'comments'],
    dontwatch: ['users'],
};
const dataProvider = FirebaseDataProvider(config);
// const firebaseRealtime = FirebaseRealTimeSaga(dataProvider);

const i18nProvider = polyglotI18nProvider(locale => {
    if (locale === 'fr') {
        return import('./i18n/fr').then(messages => messages.default);
    }
    if (locale === 'pt_BR') {
        return import('./i18n/pt').then(messages => messages.default);
    }

    // Always fallback on english
    return englishMessages;
}, 'pt');

const App = () => {
    // const [dataProvider, setDataProvider] = useState<DataProvider>();

    // useEffect(() => {
    //     let restoreFetch;
    //
    //     const fetchDataProvider = async () => {
    //         restoreFetch = await fakeServerFactory(
    //             process.env.REACT_APP_DATA_PROVIDER || ''
    //         );
    //         const dataProviderInstance = await dataProviderFactory(
    //             process.env.REACT_APP_DATA_PROVIDER || ''
    //         );
    //         setDataProvider(
    //             // GOTCHA: dataProviderInstance can be a function
    //             () => dataProviderInstance
    //         );
    //     };
    //
    //     fetchDataProvider();
    //
    //     return restoreFetch;
    // }, []);

    if (!dataProvider) {
        return (
            <div className="loader-container">
                <div className="loader">Loading...</div>
            </div>
        );
    }

    return (
        <Admin
            title=""
            dataProvider={dataProvider}
            customReducers={{ theme: themeReducer }}
            customRoutes={customRoutes}
            authProvider={authProvider}
            dashboard={Dashboard}
            loginPage={Login}
            layout={Layout}
            i18nProvider={i18nProvider}
        >
            <Resource name="customers" {...visitors} />
            <Resource
                name="commands"
                {...orders}
                options={{ label: 'Orders' }}
            />
            <Resource
                name="commands"
                list={OrderListOrdered}
                options={{ label: 'Orders' }}
            />
            <Resource name="invoices" {...invoices} />
            <Resource name="products" {...products} />
            <Resource name="categories" {...categories} />
            <Resource name="categories_power" {...categories_power} />
            <Resource
                name="categories_power_range"
                {...categories_power_range}
            />
            <Resource name="enterprises" {...enterprises} />
            <Resource name="reviews" {...reviews} />
            <Resource name="global_irradiation" {...global_irradiation} />
            <Resource name="state_irradiation" {...state_irradiation} />
            {/* <Resource name="calculator" {...calculator} /> */}
            {/* <Resource name="commands" list={OrderListOrdered} /> */}
        </Admin>
    );
};

export default App;
