import React, {
    useState,
    useEffect,
    useCallback,
    FC,
    CSSProperties,
} from 'react';
import {
    useVersion,
    useDataProvider,
    EditButton,
    ShowButton,
    RecordMap,
    Identifier,
    useGetList,
    useAuthProvider,
    useCheckAuth,
} from 'react-admin';
import { List, ListProps, useListContext } from 'react-admin';
import { useMediaQuery, Theme } from '@material-ui/core';
import { subDays } from 'date-fns';

// import Welcome from './Welcome';
import MonthlyRevenue from './MonthlyRevenue';
import NbNewOrders from './NbNewOrders';
import NbNewBudgets from './NbNewBudget';
import PendingOrders from './PendingOrders';
import PendingReviews from './PendingReviews';
import NewCustomers from './NewCustomers';
import OrderChart from './OrderChart';
import LinkToRelatedProducts from './LinkToRelatedProducts';
import GridList from '../products/GridList';
import { makeStyles } from '@material-ui/core/styles';

import { Customer, Order, Review, Product, Category } from '../types';
import inflection from 'inflection';
import {
    Grid,
    Card,
    CardMedia,
    CardContent,
    CardActions,
    Typography,
} from '@material-ui/core';

interface OrderStats {
    revenue: number;
    nbNewOrders: number;
    nbNewBudgets: number;
    pendingOrders: Order[];
}

interface CustomerData {
    [key: string]: Customer;
}

interface State {
    nbNewOrders?: number;
    nbNewBudgets?: number;
    nbPendingReviews?: number;
    pendingOrders?: Order[];
    pendingOrdersCustomers?: CustomerData;
    pendingReviews?: Review[];
    pendingReviewsCustomers?: CustomerData;
    recentOrders?: Order[];
    revenue?: string;
}

const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '0.5em' },
    rightCol: { flex: 1, marginLeft: '0.5em' },
    singleCol: { marginTop: '1em', marginBottom: '1em' },
};
const useStyles = makeStyles({
    root: {
        marginTop: '1em',
    },
    media: {
        height: 140,
    },
    title: {
        paddingBottom: '0.5em',
    },
    actionSpacer: {
        display: 'flex',
        justifyContent: 'space-around',
    },
});
interface Props {
    data?: RecordMap<Product>;
    ids?: Identifier[];
}
const Spacer = () => <span style={{ width: '1em' }} />;
const VerticalSpacer = () => <span style={{ height: '1em' }} />;
const CategoryGrid: FC<Props> = ({ data, ids }) => {
    const classes = useStyles(data);
    return data ? (
        <Grid container spacing={2} className={classes.root}>
            {ids?.map(id => (
                <Grid key={id} xs={12} sm={6} md={4} lg={3} xl={2} item>
                    <Card>
                        <CardMedia
                            image={data[id].image}
                            className={classes.media}
                        />
                        <CardContent className={classes.title}>
                            <Typography
                                variant="h5"
                                component="h2"
                                align="center"
                            >
                                {data[id].reference}
                            </Typography>
                        </CardContent>
                        <CardActions
                            classes={{ spacing: classes.actionSpacer }}
                        >
                            <ShowButton
                                label=""
                                basePath="/products"
                                record={data[id]}
                            />
                            <LinkToRelatedProducts record={data[id]} />
                        </CardActions>
                    </Card>
                </Grid>
            ))}
        </Grid>
    ) : null;
};

const Dashboard: FC = () => {
    // const checkAuth = useCheckAuth();
    // checkAuth().then(user => {
    //     // @ts-ignore
    //     var username = user.email;
    //     localStorage.setItem('username', username);
    //     // @ts-ignore
    //     var uid = user.uid;
    //     console.log('Email: ' + username);
    //     console.log('   UID: ' + uid);
    // });
    const username = localStorage.getItem('username');
    console.log(username);
    const { data, ids } = useGetList<Product>(
        'products',
        { page: 1, perPage: 8 },
        { field: 'name', order: 'ASC' },
        {}
    );
    const [state, setState] = useState<State>({});
    const version = useVersion();
    const dataProvider = useDataProvider();
    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    );
    const isSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('md')
    );

    const fetchOrders = useCallback(async () => {
        const aMonthAgo = subDays(new Date(), 30);
        const { data: recentOrders } = await dataProvider.getList<Order>(
            'commands',
            {
                filter: { owner: username },
                sort: { field: 'createdate', order: 'ASC' },
                pagination: { page: 1, perPage: 50 },
            }
        );
        const aggregations = recentOrders
            .filter(order => order.status !== 'cancelled')
            .filter(order => order.owner == username)
            .reduce(
                (stats: OrderStats, order) => {
                    if (
                        order.status !== 'cancelled' &&
                        order.status == 'ordered'
                    ) {
                        stats.nbNewOrders++;
                        stats.pendingOrders.push(order);
                    }
                    if (
                        order.status !== 'cancelled' &&
                        order.status == 'delivered'
                    ) {
                        stats.revenue += order.total;
                        stats.nbNewBudgets++;
                    }
                    return stats;
                },
                {
                    revenue: 0,
                    nbNewOrders: 0,
                    nbNewBudgets: 0,
                    pendingOrders: [],
                }
            );
        setState(state => ({
            ...state,
            recentOrders,
            revenue: aggregations.revenue.toLocaleString(undefined, {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            }),
            nbNewOrders: aggregations.nbNewOrders,
            nbNewBudgets: aggregations.nbNewBudgets,
            pendingOrders: aggregations.pendingOrders,
        }));
        const { data: customers } = await dataProvider.getMany<Customer>(
            'customers',
            {
                ids: aggregations.pendingOrders.map(
                    (order: Order) => order.customer_id
                ),
            }
        );
        setState(state => ({
            ...state,
            pendingOrdersCustomers: customers.reduce(
                (prev: CustomerData, customer) => {
                    prev[customer.id] = customer; // eslint-disable-line no-param-reassign
                    return prev;
                },
                {}
            ),
        }));
    }, [dataProvider]);

    const fetchReviews = useCallback(async () => {
        const { data: reviews } = await dataProvider.getList<Review>(
            'reviews',
            {
                filter: { status: 'pending' },
                sort: { field: 'date', order: 'DESC' },
                pagination: { page: 1, perPage: 100 },
            }
        );
        const nbPendingReviews = reviews.reduce((nb: number) => ++nb, 0);
        const pendingReviews = reviews.slice(0, Math.min(10, reviews.length));
        setState(state => ({ ...state, pendingReviews, nbPendingReviews }));
        const { data: customers } = await dataProvider.getMany<Customer>(
            'customers',
            {
                ids: pendingReviews.map(review => review.customer_id),
            }
        );
        setState(state => ({
            ...state,
            pendingReviewsCustomers: customers.reduce(
                (prev: CustomerData, customer) => {
                    prev[customer.id] = customer; // eslint-disable-line no-param-reassign
                    return prev;
                },
                {}
            ),
        }));
    }, [dataProvider]);

    useEffect(() => {
        fetchOrders();
        // fetchReviews();
    }, [version]); // eslint-disable-line react-hooks/exhaustive-deps

    const {
        nbNewOrders,
        nbNewBudgets,
        nbPendingReviews,
        pendingOrders,
        pendingOrdersCustomers,
        pendingReviews,
        pendingReviewsCustomers,
        revenue,
        recentOrders,
    } = state;
    return isXSmall ? (
        <div>
            <div style={styles.flexColumn as CSSProperties}>
                <MonthlyRevenue value={revenue} />
                <VerticalSpacer />
                <NbNewBudgets value={nbNewBudgets} />
                <VerticalSpacer />
                <NbNewOrders value={nbNewOrders} />
                <VerticalSpacer />
            </div>
            <div style={styles.singleCol}>
                <CategoryGrid data={data} ids={ids} />
                {/* <OrderChart orders={recentOrders} /> */}
            </div>
            <PendingOrders
                orders={pendingOrders}
                customers={pendingOrdersCustomers}
            />
        </div>
    ) : isSmall ? (
        <div style={styles.flexColumn as CSSProperties}>
            <div style={styles.flex}>
                <MonthlyRevenue value={revenue} />
                <Spacer />
                <NbNewBudgets value={nbNewBudgets} />
                <Spacer />
                <NbNewOrders value={nbNewOrders} />
                <Spacer />
            </div>
            <div style={styles.singleCol}>
                <CategoryGrid data={data} ids={ids} />
                {/* <OrderChart orders={recentOrders} /> */}
            </div>
            <div style={styles.singleCol}>
                <PendingOrders
                    orders={pendingOrders}
                    customers={pendingOrdersCustomers}
                />
            </div>
        </div>
    ) : (
        <>
            <div style={styles.flex}>
                <div style={styles.leftCol}>
                    <div style={styles.flex}>
                        <MonthlyRevenue value={revenue} />
                        <Spacer />
                        <NbNewBudgets value={nbNewBudgets} />
                        <Spacer />
                        <NbNewOrders value={nbNewOrders} />
                        <Spacer />
                    </div>
                    <div style={styles.singleCol}>
                        <CategoryGrid data={data} ids={ids} />
                        {/* <OrderChart orders={recentOrders} /> */}
                    </div>
                    <div style={styles.singleCol}>
                        <PendingOrders
                            orders={pendingOrders}
                            customers={pendingOrdersCustomers}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Dashboard;
