import EnterpriseIcon from '@material-ui/icons/Store';

import EnterpriseList from './EnterpriseList';
import EnterpriseEdit from './EnterpriseEdit';

export default {
    list: EnterpriseList,
    edit: EnterpriseEdit,
    icon: EnterpriseIcon,
};
