import * as React from 'react';
import { FC } from 'react';
import classnames from 'classnames';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
    FieldProps,
    useTranslate,
    useQueryWithStore,
    useGetMany,
    useGetList,
    useDataProvider,
    useVersion,
} from 'react-admin';

import { AppState, Order, Product } from '../types';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

const useStyles = makeStyles({
    container: { minWidth: '35em' },
    rightAlignedCell: { textAlign: 'right' },
    boldCell: { fontWeight: 'bold' },
});
interface State {
    products?: any;
}

const Totals: FC<FieldProps<Order>> = ({ record }: { record?: Order }) => {
    const [state, setState] = useState<State>({});
    const version = useVersion();
    const classes = useStyles();
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    console.log(record);
    const idss = record ? record.basket.map(item => item.product_id) : [];
    console.log(idss);
    var total_ex_taxes = 0;
    const { loaded, data: products } = useQueryWithStore<AppState>(
        {
            type: 'getMany',
            resource: 'products',
            payload: {
                ids: record ? record.basket.map(item => item.product_id) : [],
            },
        },
        {},
        state => {
            const productIds = record
                ? record.basket.map(item => item.product_id)
                : [];

            return productIds
                .map<Product>(
                    productId =>
                        state.admin.resources.products.data[
                            productId
                        ] as Product
                )
                .filter(r => typeof r !== 'undefined')
                .reduce((prev, next) => {
                    prev[next.id] = next;
                    return prev;
                }, {} as { [key: string]: Product });
        }
    );
    if (Object.keys(products).length !== 0 && products.constructor === Object) {
        console.log(products);
        total_ex_taxes = record
            ? record.basket
                  .map(
                      (item: any) =>
                          products[item.product_id].price * item.quantity
                  )
                  .reduce(
                      (total, currentValue) => (total = total + currentValue)
                  )
            : 0;
    }
    console.log(total_ex_taxes);
    // const fetchProducts = useCallback(async () => {
    //     const { data: products } = await dataProvider.getMany<Product>(
    //         'products',
    //         {
    //             ids: idss,
    //         }
    //     );
    //     setState((state: any) => ({ ...state, products }));
    // }, [dataProvider]);
    // useEffect(() => {
    //     fetchProducts();
    //     console.log(products);
    //     if (typeof products !== 'undefined') {
    //         total_ex_taxes = record
    //             ? record.basket
    //                   .map(
    //                       (item: any) =>
    //                           (products[item.product_id].price || 0) *
    //                           item.quantity
    //                   )
    //                   .reduce(
    //                       (total, currentValue) =>
    //                           (total = total + currentValue)
    //                   )
    //             : 0;
    //     }
    //     console.log(total_ex_taxes);
    // }, [version]);
    // const { products } = state;
    // console.log(products);
    // if (typeof products !== 'undefined') {
    //     total_ex_taxes = record
    //         ? record.basket
    //               .map(
    //                   (item: any) =>
    //                       (products[item.product_id].price || 0) * item.quantity
    //               )
    //               .reduce(
    //                   (total, currentValue) => (total = total + currentValue)
    //               )
    //         : 0;
    // }
    // console.log(total_ex_taxes);
    // const { data, ids } = useGetList<Product>(
    //     'products',
    //     { page: 1, perPage: 100 },
    //     { field: 'name', order: 'ASC' },
    //     {}
    // );
    // const { data, loading, error } = useGetMany('products', ids || []);
    // const { loaded, data: products } = useQueryWithStore<AppState>(
    //     {
    //         type: 'getMany',
    //         resource: 'products',
    //         payload: {
    //             ids: record ? record.basket.map(item => item.product_id) : [],
    //         },
    //     },
    //     {},
    //     state => {
    //         const productIds = record
    //             ? record.basket.map(item => item.product_id)
    //             : [];

    //         return productIds
    //             .map<Product>(
    //                 productId =>
    //                     state.admin.resources.products.data[
    //                         productId
    //                     ] as Product
    //             )
    //             .filter(r => typeof r !== 'undefined')
    //             .reduce((prev, next) => {
    //                 prev[next.id] = next;
    //                 return prev;
    //             }, {} as { [key: string]: Product });
    //     }
    // );
    // const result = record ? record.basket.map((item: any) => total = total + currentValue.prix,0) : 0
    // console.log((record.basket.reduce((a,v) =>  a = a + v.prix , 0 )))
    // console.log(products);
    // var total_ex_taxes = 0;
    // if (products) {
    //     total_ex_taxes = record
    //         ? record.basket
    //               .map(
    //                   (item: any) =>
    //                       (products[item.product_id].price || 0) * item.quantity
    //               )
    //               .reduce(
    //                   (total, currentValue) => (total = total + currentValue)
    //               )
    //         : 0;
    // }
    // console.log(total_ex_taxes);
    return (
        <Table className={classes.container}>
            <TableBody>
                <TableRow>
                    <TableCell className={classes.boldCell}>
                        {translate('resources.commands.fields.basket.total')}
                    </TableCell>
                    <TableCell
                        className={classnames(
                            classes.boldCell,
                            classes.rightAlignedCell
                        )}
                    >
                        {total_ex_taxes.toLocaleString(undefined, {
                            style: 'currency',
                            currency: 'BRL',
                        })}
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
};

export default Totals;
