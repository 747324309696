import * as React from 'react';
import { Fragment, useCallback, FC, useEffect, useState } from 'react';
import { useMediaQuery, Divider, Theme, CardHeader } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ProductIcon from '@material-ui/icons/ShoppingCart';
import {
    Create,
    FormTab,
    NumberInput,
    ReferenceInput,
    SelectInput,
    TabbedForm,
    TextInput,
    TextField,
    required,
    CreateProps,
    ReferenceManyField,
    Datagrid,
    DateField,
    EditButton,
    Pagination,
    List,
    BulkActionProps,
    ArrayInput,
    SimpleFormIterator,
    ShowButton,
    ReferenceArrayInput,
    SelectArrayInput,
    NumberField,
    Link,
    FormDataConsumer,
    useRefresh,
    Identifier,
    useUnselectAll,
    Filter,
    useGetMany,
    useGetList,
    useListContext,
    ListContextProvider,
    DatagridProps,
    useGetOne,
    useRedirect,
    useTranslate,
    DateInput,
    email,
    AutocompleteInput,
    TopToolbar,
    useNotify,
    Toolbar,
    SaveButton,
    CreateButton,
    Button,
    RecordMap,
    Record,
} from 'react-admin';
import { Typography, Box } from '@material-ui/core';
// import MobileGrid from './MobileGrid';
import CustomerReferenceField from '../visitors/CustomerReferenceField';
import StarRatingField from '../reviews/StarRatingField';
import { Drawer } from '@material-ui/core';
import { InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RichTextInput from 'ra-input-rich-text';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ButtonCore from '@material-ui/core/Button';
import Basket from './Basket';
import PreviewProductButton from './PreviewProductButton';
import ProductShow from '../products/ProductShow';
// import { BulkAddProductButton, basketConst } from './BulkAddProductButton';
import BulkAddItemButton from './BulkAddItemButton';
import { StateItens, Customer, StateIrradiation } from '../types';
import ThumbnailField from '../products/ThumbnailField';
import SegmentsOrderInput from './SegmentsOrderInput';
import { useForm, useFormState } from 'react-final-form';
import { SimpleFormIteratorProps } from 'ra-ui-materialui/lib/form/SimpleFormIterator';
import { parse } from 'query-string';
import { useLocation, useHistory, Route } from 'react-router-dom';
import { Component } from 'react';
import IconImageAdd from '@material-ui/icons/Add';
import { render } from 'react-dom';
// import DynamicForm from './dynamicForm';
const useListStyles = makeStyles(theme => ({
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
    },
    cardTitleContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardContent: theme.typography.body1,
    cardContentRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        margin: '0.5rem 0',
    },
}));
interface MobileGridProps {
    ids?: Identifier[];
    data?: RecordMap<Record>;
    basePath?: string;
}
// defaultProps = {
//     data: {},
//     ids: [],
// };
type Irradiation = {
    city: string | null;
    state: string | null;
    annual_irradiation: string | null;
};
const defaultIrradiation: Irradiation = {
    city: 'Fortaleza',
    state: 'CEARÁ',
    annual_irradiation: '5776',
};
export const styles = {
    price: { width: '7em' },
    id: { width: '0em' },
    quantity: { width: '6em' },
    potence: { width: '6em' },
    width: { width: '7em' },
    height: { width: '7em' },
    stock: { width: '7em' },
    widthFormGroup: { display: 'inline-block' },
    heightFormGroup: { display: 'inline-block', marginLeft: 32 },
    first_name: { display: 'inline-block' },
    last_name: { display: 'inline-block', marginLeft: 32 },
    email: { width: 544 },
    address: { maxWidth: 544 },
    zipcode: { display: 'inline-block' },
    city: { display: 'inline-block', marginLeft: 32 },
};
const useStyles = makeStyles(styles);
interface TabbedDatagridProps extends DatagridProps {}
const Separator = () => <Box pt="1em" />;

const modal: StateItens = {
    basket: [],
    bascket: [],
    status: 'ordered',
    owner: 'empty',
    delivery_fees: '0',
    description: '0',
    tax_rate: '0',
    taxes: '0',
    total: 0,
};
var irradiationAnualSelected: string = ' ';
var stateIrradiationSelected: String = ' ';
var powerRangeID: String = ' ';

const useGetTotals = (filterValues: any) => {
    const { data: totalProducts, ids: idsProducts } = useGetList(
        'products',
        { perPage: 500, page: 1 },
        { field: 'sales', order: 'ASC' },
        { ...filterValues }
    );
    const { data: totalCategories, ids: idsCategoriesPower } = useGetList(
        'categories_power',
        { perPage: 500, page: 1 },
        { field: 'name', order: 'ASC' },
        { ...filterValues }
    );
    return {
        products: totalProducts,
        products_ids: idsProducts,
        categories: totalCategories,
        categories_ids: idsCategoriesPower,
    };
};
const useGetCustomers = () => {
    const { data: totalCustomers } = useGetList(
        'customers',
        { perPage: 500, page: 1 },
        { field: 'name', order: 'ASC' },
        {}
    );
    return { customers: totalCustomers };
};

const useGetStates = () => {
    const { data: totalStates } = useGetList(
        'state_irradiation',
        { perPage: 1000, page: 1 },
        { field: 'name', order: 'ASC' },
        {}
    );
    return totalStates;
};
const useGetIrradiation = () => {
    const { data: toltalGlobal } = useGetList(
        'global_irradiation',
        { perPage: 60000, page: 1 },
        { field: 'name', order: 'ASC' },
        {}
    );
    return toltalGlobal;
};
const ShowProductButton = ({ ...props }) => {
    const handleClick = () => {
        console.log(props);
    };
    return <ButtonCore onClick={handleClick}>Visualizar</ButtonCore>;
};
const powerRange = {
    AnMQNcYUtbla4GUr0JYF: {
        max: 5,
        min: 4,
        name: '4 - 5 kWp',
    },
    CUs9KHMgid8dotuN6f8r: {
        max: 50,
        name: '30 - 50 kWp',
        min: 30,
    },
    EIDxYSmGXopZAReSscPT: {
        name: '100+ kWp',
        max: 200000,
        min: 100,
    },
    JsJzA5uRAzMjOxfw9UO8: {
        name: '2 - 3 kWp',
        max: 3,
        min: 2,
    },
    OIrrO7RkyjrTD0nvjG0K: {
        max: 6,
        min: 5,
        name: '5 - 6 kWp',
    },
    SfyzpB8vtHQKJjfxH0j7: {
        name: '1 - 2 kWp',
        max: 2,
        min: 1,
    },
    Uy7jkH6xSmVDP27cm1jG: {
        name: 'Não definido',
        max: 8000000,
        min: 8000000,
    },
    Vk4eZ2kHqme44KBnHIki: {
        min: 7,
        name: '7 - 8 kWp',
        max: 8,
    },
    XhfOGpsKJpyTuPwZk6eo: {
        max: 1,
        name: '0 - 1 kWp',
        min: 0,
    },
    ep4cFXqsKgPou3K7ZA0b: {
        name: '6 - 7 kWp',
        max: 7,
        min: 6,
    },
    hg0OSjxJUZc3MIf4CIef: {
        max: 100,
        min: 50,
        name: '50 - 100 kWp',
    },
    jVmil7cRaPKbbmHFnGeL: {
        max: 10,
        name: '9 - 10 kWp',
        min: 9,
    },
    kunudP3z4Z8Gu4lDmZHx: {
        min: 10,
        max: 30,
        name: '10 - 30 kWp',
    },
    pDVDpj9TIw6RHeAjYGxF: {
        max: 9,
        name: '8 - 9 kWp',
        min: 8,
    },
    r7PRBCnAcKRbNKDFqCGl: {
        name: '3 - 4 kWp',
        max: 4,
        min: 3,
    },
};
function myFunctionCategoryPowerRange(power: number) {
    var categories_power_range = 'Uy7jkH6xSmVDP27cm1jG';
    Object.entries(powerRange).map(function (key, index) {
        var x = parseFloat(power.toFixed());
        if (x >= key[1].min && x <= key[1].max) {
            categories_power_range = key[0];
        }
    });
    return categories_power_range;
}
const MobileGrid: FC<MobileGridProps> = ({ ids, data, basePath }) => {
    const translate = useTranslate();
    const classes = useListStyles();

    if (!ids || !data || !basePath) {
        return null;
    }

    return (
        <div style={{ margin: '1em' }}>
            {ids.map(id => (
                <Card key={id} className={classes.card}>
                    <CardHeader
                        title={
                            <div className={classes.cardTitleContent}>
                                <span>
                                    {translate('resources.commands.kits', 1)}
                                    :&nbsp;
                                    <TextField
                                        record={data[id]}
                                        source="reference"
                                    />
                                </span>
                            </div>
                        }
                    />
                    <CardContent className={classes.cardContent}>
                        <span className={classes.cardContentRow}>
                            {'Preço (R$)'}:&nbsp;
                            <NumberField
                                source="price"
                                options={{
                                    style: 'currency',
                                    currency: 'BRL',
                                }}
                                record={data[id]}
                            />
                        </span>
                        <span className={classes.cardContentRow}>
                            {'Potência (kWp)'}:&nbsp;
                            <TextField record={data[id]} source="power" />
                        </span>
                    </CardContent>
                </Card>
            ))}
        </div>
    );
};
const AddProductButton = (props: any) => {
    // record: state.admin.resources[props.resource]
    //     ? state.admin.resources[props.resource].data[props.id]
    //     : null,
    const refresh = useRefresh();
    const form = useForm();
    const handleClick = () => {
        console.log(props.record);
        // @ts-ignore
        var id = props.record.id as string;
        var name = props.record.reference as string;
        var power = props.record.power as string;
        var price = props.record.price as number;
        modal.basket.push({
            product_id: id,
            name: name,
            potence: power,
            price: price,
            quantity: 1,
        });
        var total = getTotalItens();
        modal.total = total;
        // form.change('total', total);
        // props.record.basket = modal;
        console.log(modal);
        // basket
        // form.change('basket', modal);
        form.change('bascket', modal);
        console.log(form);
        console.log(form.getState());
        refresh();
    };
    return (
        <Fragment>
            <Button onClick={handleClick} label="Adicionar">
                <ProductIcon />
            </Button>
        </Fragment>
    );
};

const CustomRemoveButton = (removeButton: any, _index: any) => {
    // const CustomRemoveButton = ({ ...props }) => {
    console.log(removeButton);
    const form = useForm();
    const handleClick = () => {
        removeButton.onClick();
        var myString = removeButton.className as String;
        var index = myString.substring(myString.length - 1);
        console.log(index);
        console.log(myString);
        var y: number = +index;
        modal.basket.splice(y, 1);
        var total = getTotalItens();
        modal.total = total;
        // form.change('total', total);
    };
    return <ButtonCore onClick={handleClick}>Remover</ButtonCore>;
};
const ProductsBulkActionButtonAdd = (props: BulkActionProps) => {
    // const selectedIds = props['selectedIds'];
    // console.log(selectedIds);
    // console.log(itens);
    const form = useForm();
    console.log(form);
    console.log(form.getState());
    const refresh = useRefresh();
    const unselectAll = useUnselectAll('products');
    var formdata = form.getFieldState('address');
    var formdataproducts = form.getFieldState('products');
    var formdataValues = form.getState().values;
    // const { data: referenceRecordsFetched, loaded } = useGetMany(
    //     reference,
    //     props.selectedIds || []
    // );
    const { data, loading, error } = useGetMany(
        'products',
        props.selectedIds || []
    );
    console.log(data);
    const handleClick = () => {
        console.log(props);
        console.log(props.selectedIds);
        // @ts-ignore
        props.selectedIds.forEach((item, index) => {
            var id = item as string;
            var name = data[index].reference as string;
            var power = data[index].power as string;
            var price = data[index].price as number;
            modal.basket.push({
                product_id: id,
                name: name,
                potence: power,
                price: price,
                quantity: 1,
            });
        });
        var total = getTotalItens();
        modal.total = total;
        // form.change('total', total);
        // props.basket = modal;
        console.log(modal);
        form.change('bascket', modal);
        // form.change('basket', modal);
        console.log(form);
        console.log(form.getState());
        // useFriendStatus(modal);
        // setItens(modal);
        refresh();
        unselectAll();
    };
    // useFriendStatus(modal);
    return <ButtonCore onClick={handleClick}>Adicionar</ButtonCore>;
};

const PostFilter = ({ ...props }) => {
    // var names = form.getState().values['categories_power_id'];
    // console.log(sistemarecomendado);
    // console.log(names);
    // console.log(form);
    // form.change('categories_power_id_testec', sistemarecomendado);
    // var names = form.getState().values['categories_power_id_testec'];
    // refresh();
    // props.setFilters({
    //     categories_power_id: 'x52ueklrSN6kHOhDbas4',
    // });
    // var names = 'x52ueklrSN6kHOhDbas4';
    // filterDefaultValues="x52ueklrSN6kHOhDbas4"
    var powerRangeId = '';
    const form = useForm();
    var sistemarecomendado = form.getState().values['recommended_system'];
    var powerRangeId = '';
    if (typeof sistemarecomendado !== 'undefined') {
        var power = parseFloat(sistemarecomendado);
        powerRangeId = myFunctionCategoryPowerRange(power);
    }
    console.log('powerRangeId');
    console.log(powerRangeId);
    powerRangeID = powerRangeId;
    return (
        <Filter {...props}>
            <NumberInput
                label="Pesquisar por potência"
                source="power"
                type="number"
                submitOnEnter={false}
                alwaysOn
            />
            <ReferenceInput
                label="Potências"
                defaultValue={powerRangeId}
                sort={{ field: '_id', order: 'ASC' }}
                source="categories_power_range_id"
                reference="categories_power_range"
                submitOnEnter={false}
                allowEmpty
                alwaysOn
            >
                <SelectInput optionText="name" source="name" />
            </ReferenceInput>
        </Filter>
    );
};

const ProductsBulkActionButtons = (props: BulkActionProps) => (
    <Fragment>
        {/* <BulkAddProductButton {...props} /> */}
        <BulkAddItemButton {...props} />
    </Fragment>
);
const CreateNewClientButton = (_props: any) => (
    <ButtonCore
        variant="contained"
        component={Link}
        to={{
            pathname: '/customers/create',
        }}
    >
        Cadastre um novo cliente
    </ButtonCore>
);
const FillClientFieldsButton = ({ ...props }) => {
    const form = useForm();
    var formdata = form.getFieldState('address');
    var formdataValues = form.getState().values;
    const { data, loading, error } = useGetMany('customers', []);
    console.log(data);
    const handleClick = useCallback(() => {
        var id = form.getState().values['customer_id'];
        console.log(data);
        var customer = data[id];
        if (customer) {
            console.log(customer);
            form.change('first_name', customer.first_name);
            form.change('last_name', customer.last_name);
        }
    }, [form]);

    return (
        <ButtonCore color="primary" onClick={handleClick} {...props}>
            {' '}
            Preencher os dados do integrador?
        </ButtonCore>
    );
};
const CalculeFieldsButton = ({ ...props }) => {
    const form = useForm();
    var formdata = form.getFieldState('address');
    var formdataValues = form.getState().values;
    const handleClick = useCallback(() => {
        var average_monthly_consumption = form.getState().values[
            'average_monthly_consumption'
        ];
        var generation_percentage = form.getState().values[
            'generation_percentage'
        ];
        var system_losses = form.getState().values['system_losses'];
        generation_percentage = generation_percentage / 100;
        system_losses = system_losses / 100;
        console.log(`system_losses: ${system_losses}`);
        console.log(`generation_percentage: ${generation_percentage}`);
        var irradion = parseInt(irradiationAnualSelected);
        // var average_monthly_consumption = 19.05833;
        // var irradion = 5.8;
        // var generation_percentage = 1.1;
        // var system_losses = 0.185;
        var operation0 = irradion / 1000;
        console.log(`operation0: ${operation0}`);
        var operation1 = average_monthly_consumption / operation0;
        console.log(`operation1: ${operation1}`);
        var operation2 = operation1 * 1000 * generation_percentage;
        console.log(`operation2: ${operation2}`);
        var losses = 1 - system_losses;
        console.log(`losses: ${losses}`);
        var power = operation2 / losses;
        var powerk = power / 1000;
        console.log(`Power do sistema: ${power}`);
        form.change('recommended_system', powerk);
        form.change('power', powerk);
    }, [form]);

    return (
        <ButtonCore onClick={handleClick} {...props}>
            {' '}
            Calcular
        </ButtonCore>
    );
};
const BasketItemFieldsButton = (_props: any) => (
    <Fragment>
        <ReferenceInput
            label="Produto"
            source="product_id"
            reference="products"
            allowEmpty
        >
            <SelectInput optionText="reference" source="name" />
        </ReferenceInput>
        <NumberInput
            label="Quantidade"
            validate={required()}
            source="quantity"
        />
    </Fragment>
);
const BasketItemFieldsTotal = ({ ...props }) => {
    const form = useForm();
    var formdata = form.getFieldState('address');
    var formdataValues = form.getState().values;
    const handleClick = useCallback(() => {
        var name = form.getState().values['name'];
        console.log(name);
        form.change('salesman', name);
        form.change('system_losses', name);
    }, [form]);

    return (
        <ButtonCore onClick={handleClick} {...props}>
            {' '}
            Calcular
        </ButtonCore>
    );
};

// const BasketItem = ({ record }: { record?: Order }) => (
//     <ArrayInput source="basket">
//         <SimpleFormIterator>
//         <ReferenceInput source="product_id" reference="products" allowEmpty>
//             <SelectInput source="name" />
//         </ReferenceInput>
//             <TextInput source="quantity" />
//         </SimpleFormIterator>
//     </ArrayInput>
// );
// const FormControl = (event: { target: { name: any; value: any } }) => {
//     const { name, value } = event.target;
//     // const [stateData, setStateData] = useReducer(
//     //     (state: any, newState: any) => ({ ...state, ...newState }),
//     //     {
//     //         categories_power_id: '-',
//     //     }
//     // );
//     console.log(name);
//     console.log(value);
//     // setStateData({ [name]: value });
//     // setStateData({ categories_power_id: '...' });
//     // form.change('categories_power_id', sistemarecomendado);
//     // var names = form.getState().values['categories_power_id_testec'];
// };
const FormControl = (event: { target: { name: any; value: any } }) => {
    const { name, value } = event.target;
    // const [stateData, setStateData] = useReducer(
    //     (state: any, newState: any) => ({ ...state, ...newState }),
    //     {
    //         categories_power_id: '-',
    //     }
    // );
    console.log(name);
    console.log(value);
    // setStateData({ [name]: value });
    // setStateData({ categories_power_id: '...' });
    // form.change('categories_power_id', sistemarecomendado);
    // var names = form.getState().values['categories_power_id_testec'];
};
const CustomActions = (basePath: any, data: any) => (
    <TopToolbar>
        <ShowButton basePath={basePath} record={data} />
        {/* Add your custom actions */}
        {/* <Button color="primary" onClick={customAction}>Custom Action</Button> */}
    </TopToolbar>
);
const TabbedDatagrid: FC<TabbedDatagridProps> = props => {
    const isXSmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('xs')
    );
    const form = useForm();
    const listContext = useListContext();
    const { ids, filterValues, setFilters, displayedFilters } = listContext;
    const totals = useGetTotals(filterValues) as any;
    const [products, setProducts] = useState<Identifier[]>([] as Identifier[]);
    console.log(totals);
    var name = form.getState().values['recommended_system'];
    console.log(name);
    useEffect(() => {
        // if (typeof name == 'undefined') {
        //     ids.pop();
        // }
        // const newsIds = ids.filter(
        //     p => totals.products[p].categories_power_id == name
        // );
        console.log(ids);
        if (ids && ids !== filterValues.categories_power_id) {
            switch (filterValues.categories_power_id) {
                case 'x52ueklrSN6kHOhDbas4':
                    setProducts(ids);
                    break;
                case 'X2AnbRm6OlyceWMIntw2':
                    setProducts(ids);
                    break;
                default:
                    setProducts(ids);
                    break;
            }
        }
    }, [ids, filterValues.categories_power_id]);

    const handleChange = useCallback(
        (_event: React.ChangeEvent<{}>, value: any) => {
            setFilters &&
                setFilters(
                    { ...filterValues, categories_power_id: value },
                    displayedFilters
                );
        },
        [displayedFilters, filterValues, setFilters]
    );

    const selectedIds =
        filterValues.categories_power_id === 'x52ueklrSN6kHOhDbas4'
            ? products
            : filterValues.categories_power_id === 'X2AnbRm6OlyceWMIntw2'
            ? products
            : products;

    return (
        <Fragment>
            {/* {isXSmall ? (
                <ListContextProvider
                    value={{ ...listContext, ids: selectedIds }}
                >
                    <MobileGrid {...props} ids={selectedIds} />
                </ListContextProvider>
            ) : (
            )} */}
            {
                <div>
                    {
                        <ListContextProvider
                            value={{ ...listContext, ids: products }}
                        >
                            {/* <Datagrid {...props} rowClick={handleClick}> */}
                            <Datagrid {...props}>
                                <ThumbnailField />
                                <TextField label="Nome" source="reference" />
                                <NumberField
                                    label="Preço (R$)"
                                    source="price"
                                    options={{
                                        style: 'currency',
                                        currency: 'BRL',
                                    }}
                                />
                                <TextField
                                    label="Potência (kWp)"
                                    source="power"
                                />
                                <AddProductButton />
                                <PreviewProductButton />
                            </Datagrid>
                        </ListContextProvider>
                    }
                    {filterValues.categories_power_id ===
                        'X2AnbRm6OlyceWMIntw2' && (
                        <ListContextProvider
                            value={{ ...listContext, ids: products }}
                        >
                            <Datagrid {...props}>
                                <ThumbnailField />
                                <TextField label="Nome" source="reference" />
                                <NumberField
                                    label="Preço"
                                    source="price"
                                    options={{
                                        style: 'currency',
                                        currency: 'BRL',
                                    }}
                                />
                                <TextField
                                    label="Potência (kWp)"
                                    source="power"
                                />
                                <AddProductButton />
                                <PreviewProductButton />
                            </Datagrid>
                        </ListContextProvider>
                    )}
                </div>
            }
        </Fragment>
    );
};
// function useFriendStatus(modalBascket: StateItens) {
//     return itens;
// }
function getTotalItens() {
    var total_ex_taxes = modal
        ? modal.basket
              .map((item: any) => item.price * item.quantity)
              .reduce((total, currentValue) => (total = total + currentValue))
        : 0;
    return total_ex_taxes;
}
const PostEditActions = (basePath: any, data: any) => {
    console.log(basePath);
    console.log(data);
    var labelNext = 'Próximo';
    var pathActionNext = '/commands/create/1';
    var labelBack = 'Voltar';
    var pathActionBack = '/commands/create/products';
    return (
        <TopToolbar>
            <ButtonCore
                variant="contained"
                component={Link}
                to={{
                    pathname: pathActionBack,
                }}
            >
                {labelBack}
            </ButtonCore>
            <ButtonCore
                variant="contained"
                component={Link}
                to={{
                    pathname: pathActionNext,
                }}
            >
                {labelNext}
            </ButtonCore>
        </TopToolbar>
    );
};
const PostEditToolbar = (props: any) => {
    console.log(props.location.pathname);
    var path = props.location.pathname;
    var label = 'Próximo';
    var pathAction = 'Próximo';
    var showSave = false;
    if (path == '/commands/create/1') {
        showSave = true;
        label = 'Voltar';
        pathAction = '/commands/create/products';
    }
    if (path == '/commands/create/products') {
        showSave = false;
        label = 'Próximo';
        pathAction = '/commands/create/1';
    }
    return (
        <Toolbar {...props}>
            <ButtonCore
                variant="contained"
                component={Link}
                to={{
                    pathname: pathAction,
                }}
            >
                {label}
            </ButtonCore>
            {showSave ? (
                <SaveButton
                    disabled={props.pristine}
                    redirect={`/commands/`}
                    validate={required()}
                    {...props}
                />
            ) : null}
            {/* <Button to={{ pathname: `/post/create/${index}`, state: { skipFormReset: true } }}>Visualizar</Button>; */}
            {/* <Route basePath="/commands/create/1" /> */}
        </Toolbar>
    );
};
const OrderCreate: FC<CreateProps> = props => {
    const [itens, setItens] = useState<StateItens>();
    const [irradiationAnual, setIrradiationAnual] = useState('5776');
    const [state_irradiation_select, setStateIrradiation] = useState('CEARÁ');
    const classes = useStyles();
    const redirect = useRedirect();
    var history = useHistory();
    const notify = useNotify();
    // const form = useForm();
    const username = localStorage.getItem('username');
    modal.owner = username ? username : 'empty';
    var product_id = ' ';
    if (history.location) {
        // if (props.location.search) {Read the post_id from the location which is injected by React Router and passed to our component by react-admin automatically
        const { product_id: product_id_string } = parse(
            history.location.search
        );
        product_id = product_id_string;
        console.log(product_id);
        // @ts-expect-error Aqui vai ocorrer um erro, mas estou ignorando
        delete history.location.search;
    }
    // if (props.location) {
    //     // Read the post_id from the location which is injected by React Router and passed to our component by react-admin automatically
    //     const { product_id: product_id_string } = parse(props.location.state as string);
    //     product_id = product_id_string;
    //     console.log(product_id);
    //     redirect(false);
    // }
    const states_irradiation = useGetStates();
    const global_irradiation = useGetIrradiation();
    const { data: product } = useGetOne('products', product_id);
    if (product) {
        if (!modal.basket.some(basket => basket.product_id === product.id)) {
            modal.basket.push({
                product_id: product.id,
                name: product.reference,
                potence: product.power,
                price: product.price,
                quantity: 1,
            });
            var total = getTotalItens();
            modal.total = total;
            // form.change('total', total);
        }
    }
    product_id = ' ';
    // const autoFillAddress = (event)=>{
    //     if(event.annual){
    //         event.address
    //       if(event.cep.length === 9){
    //         endereco(event.cep).then((result)=>{
    //           event.address = result.logradouro;
    //           event.state = result.uf;
    //           event.city = result.localidade;
    //         });
    //       }
    //     }
    //   }
    // if (history.location.state || history.location.search) {
    //     history.replace({
    //         ...history.location,
    //         state: {},
    //         search: undefined,
    //     });
    // }

    // const form = useForm();
    // var sistemarecomendado = form.getState().values['recommended_system'];
    // const optionRenderer = choice => `${choice.first_name} ${choice.last_name}`;
    // @ts-ignore
    // const { stateAddress } = this.state;
    // @ts-ignore
    // const { annual_irradiation } = this.state;
    const onStateChange = <P extends keyof string>(value: string) => {
        console.log(`Novo esdado: ${value}`);
        // console.log(`Novo esdado: ${states_irradiation}`);
        // @ts-ignore
        var state_irradiation_select_op = states_irradiation[value];
        console.log(`Novo esdado: ${state_irradiation_select_op.name}`);
        // @ts-ignore
        setStateIrradiation(state_irradiation_select_op.name);
        stateIrradiationSelected = state_irradiation_select_op.name;
    };
    const onIrradiationChange = <P extends keyof string>(value: string) => {
        console.log(`Novo irradiation: ${value}`);
        // @ts-ignore
        var irradiation_annual_select = global_irradiation[value].ANNUAL;
        setIrradiationAnual(irradiation_annual_select);
        irradiationAnualSelected = irradiation_annual_select;
    };
    // var itens = useFriendStatus(modal);
    useEffect(() => {
        setItens(modal);
    }, [modal]);
    // modal.basket = [];
    const onSuccess = () => {
        modal.basket = [];
        notify('ra.notification.created', 'info', { smart_count: 1 });
        redirect('/commands');
        // notify(`Changes saved`)
        // redirect('/posts');
        // refresh();
    };
    return (
        <Create onSuccess={onSuccess} actions={<PostEditActions />} {...props}>
            <TabbedForm
                initialValues={itens}
                toolbar={<PostEditToolbar {...props} />}
            >
                <FormTab
                    label="resources.new_commands.tabs.kit"
                    path="products"
                >
                    <ArrayInput label="Carrinho de compras" source="basket">
                        <SimpleFormIterator
                            disableAdd
                            removeButton={<CustomRemoveButton />}
                        >
                            {/* <BasketItemFieldsButton /> */}
                            {/* <BasketItemFieldsButton /> */}
                            <FormDataConsumer>
                                {({ getSource, scopedFormData }) => (
                                    <>
                                        <TextInput
                                            disabled
                                            label=""
                                            className={classes.id}
                                            source={
                                                getSource
                                                    ? getSource('product_id')
                                                    : ''
                                            }
                                        />
                                        <TextInput
                                            disabled
                                            label="Nome"
                                            source={
                                                getSource
                                                    ? getSource('name')
                                                    : ''
                                            }
                                        />
                                        <TextInput
                                            disabled
                                            label="Potência"
                                            className={classes.potence}
                                            source={
                                                getSource
                                                    ? getSource('potence')
                                                    : ''
                                            }
                                        />
                                        <NumberInput
                                            label="Quantidade"
                                            className={classes.quantity}
                                            onChange={FormNumber}
                                            validate={required()}
                                            source={
                                                getSource
                                                    ? getSource('quantity')
                                                    : ''
                                            }
                                        />
                                    </>
                                )}
                            </FormDataConsumer>
                            {/* <FormDataConsumer>
                                {({
                                    formData, // The whole form data
                                    scopedFormData, // The data for this item of the ArrayInput
                                    getSource, // A function to get the valid source inside an ArrayInput
                                    ...rest
                                }) => {
                                    console.log(
                                        `getSource: ${JSON.stringify(
                                            scopedFormData
                                        )}`
                                    );
                                    return scopedFormData ? (
                                        <TextField
                                            source={getSource('quantity')}
                                            record={scopedFormData}
                                            {...rest}
                                        />
                                    ) : null;
                                }}
                            </FormDataConsumer> */}
                        </SimpleFormIterator>
                    </ArrayInput>
                    <ReferenceManyField
                        reference="products"
                        target="product_id"
                        addLabel={false}
                        fullWidth
                    >
                        <List
                            {...props}
                            bulkActionButtons={<ProductsBulkActionButtonAdd />}
                            // actions={<CustomActions />}
                            perPage={10}
                            sort={{ field: 'power', order: 'ASC' }}
                            exporter={false}
                            // filterDefaultValues={{
                            //     categories_power_range_id: { powerRangeID },
                            // }}
                            filters={<PostFilter />}
                        >
                            <TabbedDatagrid />
                        </List>
                    </ReferenceManyField>
                    <div>
                        <TextInput
                            disabled
                            label=""
                            source="status"
                            defaultValue="ordered"
                            className={classes.id}
                        />
                        <TextInput
                            disabled
                            label=""
                            className={classes.id}
                            source="total_ex_taxes"
                            defaultValue="0"
                        />
                        <TextInput
                            disabled
                            label=""
                            source="taxes"
                            defaultValue="0"
                            className={classes.id}
                        />
                        <TextInput
                            disabled
                            label=""
                            source="tax_rate"
                            defaultValue="0"
                            className={classes.id}
                        />
                        <TextInput
                            disabled
                            label=""
                            source="delivery_fees"
                            defaultValue="0"
                            className={classes.id}
                        />
                        <TextInput
                            disabled
                            label=""
                            source="description"
                            className={classes.id}
                        />
                    </div>
                </FormTab>
                <FormTab label="resources.new_commands.tabs.customer">
                    <ReferenceInput
                        label="Integrador"
                        source="customer_id"
                        filter={{ email: username }}
                        reference="customers"
                        validate={required()}
                    >
                        <SelectInput
                            optionText="first_name"
                            source="first_name"
                        />
                    </ReferenceInput>
                    {/* <FillClientFieldsButton /> */}
                    <Separator />
                    <SectionTitle label="Dados do cliente" />
                    <TextInput
                        autoFocus
                        source="first_name"
                        label="Nome"
                        formClassName={classes.first_name}
                        validate={requiredValidate}
                    />
                    <TextInput
                        source="last_name"
                        label="Sobrenome"
                        formClassName={classes.last_name}
                        validate={requiredValidate}
                    />
                    <TextInput
                        type="email"
                        source="email"
                        validation={{ email: true }}
                        fullWidth
                        formClassName={classes.email}
                        validate={[required(), email()]}
                    />
                    <NumberInput source="phone" label="Telefone" />
                    <DateInput source="birthday" />
                    <Separator />
                    <SectionTitle label="resources.customers.fieldGroups.address" />
                    <TextInput
                        source="address"
                        label="Endereço"
                        formClassName={classes.address}
                        multiline
                        fullWidth
                        helperText={false}
                        validate={required()}
                    />
                    <TextInput
                        source="zipcode"
                        label="CEP"
                        formClassName={classes.zipcode}
                        validate={required()}
                        helperText={false}
                    />
                    <TextInput
                        source="city"
                        label="Cidade"
                        formClassName={classes.city}
                        validate={required()}
                        helperText={false}
                    />
                    <Separator />
                    <Separator />
                    <SectionTitle label="Empresa" />
                    <TextInput
                        source="Razão Social"
                        formClassName={classes.address}
                        multiline
                        fullWidth
                        helperText={false}
                    />
                    <TextInput
                        source="Fantasia"
                        formClassName={classes.address}
                        multiline
                        fullWidth
                        helperText={false}
                    />
                    <TextInput
                        source="CNPJ"
                        formClassName={classes.zipcode}
                        helperText={false}
                    />
                    <TextInput
                        source="CNAE"
                        formClassName={classes.city}
                        helperText={false}
                    />
                    <TextInput
                        source="Site"
                        formClassName={classes.address}
                        multiline
                        fullWidth
                        helperText={false}
                    />
                    <Separator />
                </FormTab>
            </TabbedForm>
        </Create>
    );
};
const FormNumber = (event: { target: { name: any; value: any } }) => {
    const { name, value } = event.target;
    var number = name.match(/\d/g);
    number = number.join('');
    var index = parseInt(number);
    var valueNumber = parseInt(value);
    console.log(index);
    console.log(name);
    console.log(value);
    modal.basket[index].quantity = valueNumber;
    var total = getTotalItens();
    modal.total = total;
};
export default OrderCreate;
const requiredValidate = [required()];

const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label)}
        </Typography>
    );
};
