import ProductIcon from '@material-ui/icons/ShoppingCart';
import ProductList from './ProductList';
import PosterShow from './PosterShow';
import { ShowGuesser } from 'react-admin';

export default {
    list: ProductList,
    show: PosterShow,
    icon: ProductIcon,
};
