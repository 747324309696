import * as React from 'react';
import { FC } from 'react';
import { EditButton, List, ListProps, useListContext } from 'react-admin';
// import inflection from 'inflection';
import {
    Grid,
    Card,
    CardMedia,
    CardContent,
    CardActions,
    Typography,
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';

import LinkToRelatedProducts from './LinkToRelatedProducts';
import { CategoryPower } from '../types';
import { useTranslate, Title } from 'react-admin';

const useStyles = makeStyles({
    root: {
        marginTop: '1em',
    },
    media: {
        height: 140,
    },
    title: {
        paddingBottom: '0.5em',
    },
    actionSpacer: {
        display: 'flex',
        justifyContent: 'space-around',
    },
});

const CategoryGrid: FC = props => {
    const classes = useStyles(props);
    const { data, ids } = useListContext<CategoryPower>();
    return ids ? (
        <Grid container spacing={2} className={classes.root}>
            {ids.map(id => (
                <Grid key={id} xs={12} sm={6} md={4} lg={3} xl={2} item>
                    <Card>
                        <CardMedia
                            image={data[id].name}
                            className={classes.media}
                        />
                        <CardContent className={classes.title}>
                            <Typography
                                variant="h5"
                                component="h2"
                                align="center"
                            >
                                {data[id].name}
                            </Typography>
                        </CardContent>
                        <CardActions
                            classes={{ spacing: classes.actionSpacer }}
                        >
                            <LinkToRelatedProducts record={data[id]} />
                            <EditButton
                                basePath="/categories_power"
                                record={data[id]}
                            />
                        </CardActions>
                    </Card>
                </Grid>
            ))}
        </Grid>
    ) : null;
};

const CategoryPowerTable: FC = props => {
    const classes = useStyles(props);
    const translate = useTranslate();
    const { data, ids } = useListContext<CategoryPower>();
    return ids ? (
        <Card className={classes.root}>
            <Title
                title={translate('resources.segments.name', { smart_count: 2 })}
            />
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>{'Potência'}</TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {ids.map(id => (
                        <TableRow key={id}>
                            <TableCell>{data[id].name + ' kWp'}</TableCell>
                            <TableCell>
                                <LinkToRelatedProducts record={data[id]} />
                            </TableCell>
                            <TableCell>
                                <EditButton
                                    basePath="/categories_power"
                                    record={data[id]}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Card>
    ) : null;
};

const CategoryPowerList: FC<ListProps> = props => (
    <List
        {...props}
        sort={{ field: 'name', order: 'ASC' }}
        perPage={100}
        pagination={false}
        component="div"
        actions={false}
    >
        <CategoryPowerTable />
    </List>
);

export default CategoryPowerList;
