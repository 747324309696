import CategoryIcon from '@material-ui/icons/Bookmark';

import CategoryPowerList from './CategoryPowerList';
import CategoryPowerEdit from './CategoryPowerEdit';

export default {
    list: CategoryPowerList,
    edit: CategoryPowerEdit,
    icon: CategoryIcon,
};
