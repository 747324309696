import { TranslationMessages } from 'react-admin';
import englishMessages from 'ra-language-english';

const customEnglishMessages: TranslationMessages = {
    ...englishMessages,
    pos: {
        search: 'Procurar',
        configuration: 'Configurações',
        language: 'Idioma',
        theme: {
            name: 'Tema',
            light: 'Light',
            dark: 'Dark',
        },
        dashboard: {
            monthly_revenue: 'Receita mensal',
            month_history: 'Histórico de receita de 30 dias',
            new_orders: 'Novos orçamentos',
            new_budgets: 'Novos pedidos',
            pending_reviews: 'Pending Reviews',
            all_reviews: 'See all reviews',
            new_customers: 'Novos integradores',
            all_customers: 'Mostrar todos os usuários',
            pending_orders: 'Orçamentos pendentes',
            order: {
                items:
                    'por %{customer_name}, um item |||| por %{customer_name}, %{nb_items} itens',
            },
            welcome: {
                title: 'Bem-vindo',
                subtitle: 'ComparaSol',
            },
        },
        menu: {
            sales: 'Orçamentos e Notas',
            catalog: 'Produtos',
        },
    },
    resources: {
        customers: {
            name: 'Integrador |||| Integradores',
            fields: {
                commands: 'Orçamentos',
                first_seen: 'Primeiro acesso',
                groups: 'Seguimento',
                last_seen: 'Último acesso',
                last_seen_gte: 'Acessado desde',
                name: 'Nome',
                total_spent: 'Total de gasto',
                password: 'Senha',
                confirm_password: 'Confirmar senha',
                stateAbbr: 'State',
            },
            filters: {
                last_visited: 'Último acesso',
                today: 'Hoje',
                this_week: 'Essa semana',
                last_week: 'Última semana',
                this_month: 'Esse mês',
                last_month: 'Último mês',
                earlier: 'Mais cedo',
                has_ordered: 'Orçado',
                has_newsletter: 'É assinante',
                group: 'Seguimento',
            },
            fieldGroups: {
                identity: 'Dados pessoais',
                address: 'Endereço',
                stats: 'Estatísticas',
                history: 'Histórico',
                password: 'Senha',
                change_password: 'Mudar Senha',
            },
            page: {
                delete: 'Deletar usuário',
            },
            errors: {
                password_mismatch:
                    'A senha não é igual a confirmação da senha.',
            },
        },
        new_commands: {
            name: 'Novo Orçamento |||| Novos Orçamentos',
            amount: '1 orçamento |||| %{smart_count} orçamentos',
            title: 'Orçamento %{reference}',
            fields: {
                basket: {
                    delivery: 'Entrega',
                    reference: 'Nome',
                    quantity: 'Quantidade',
                    sum: 'Soma',
                    tax_rate: 'Taxa de imposto',
                    taxes: 'Taxa',
                    total: 'Total',
                    unit_price: 'Preço unitário',
                },
                address: 'Endereço',
                customer_id: 'Integrador',
                date_gte: 'Aprovado desde',
                date_lte: 'Aprovado antes',
                nb_items: 'Nb Itens',
                total_gte: 'Quantidade mínima',
                status: 'Status',
                returned: 'Retornou',
            },
            tabs: {
                customer: 'Cliente',
                calculator: 'Calculadora',
                kit: 'Kits',
                resume: 'Resumo',
                create: 'Criar',
            },
            action: {
                add: 'Adicionar',
            },
            section: {
                order: 'Orçamento',
                customer: 'Integrador',
                shipping_address: 'Endereço de entrega',
                items: 'Itens',
                total: 'Total',
            },
        },
        commands: {
            name: 'Orçamento |||| Orçamentos',
            kits: 'Kit |||| Kits',
            ordered: 'Orçamentos',
            my_ordered: 'Meus orçamentos',
            new_ordered: 'Novo orçamento',
            delivered: 'Pedidos',
            active: 'Ativos',
            cancelled: 'Cancelados',
            calculator: 'Calculadora',
            amount: '1 orçamento |||| %{smart_count} orçamentos',
            title: 'Orçamento %{reference}',
            fields: {
                basket: {
                    delivery: 'Entrega',
                    reference: 'Nome',
                    quantity: 'Quantidade',
                    sum: 'Soma',
                    tax_rate: 'Taxa de imposto',
                    taxes: 'Taxa',
                    total: 'Total',
                    unit_price: 'Preço unitário',
                },
                address: 'Endereço',
                customer_id: 'Integrador',
                date_gte: 'Aprovado desde',
                date_lte: 'Aprovado antes',
                nb_items: 'Nb Itens',
                total_gte: 'Quantidade mínima',
                status: 'Status',
                returned: 'Retornou',
            },
            section: {
                order: 'Orçamento',
                customer: 'Integrador',
                shipping_address: 'Endereço de entrega',
                items: 'Itens',
                total: 'Total',
            },
        },
        invoices: {
            name: 'Concluído |||| Concluídos',
            fields: {
                date: 'Data da nota',
                customer_id: 'Integrador',
                command_id: 'Pedido',
                date_gte: 'Aprovado desde',
                date_lte: 'Aprovado antes',
                total_gte: 'Quantidade mínima',
                address: 'Endereço',
            },
        },
        products: {
            name: 'Kit Fechado |||| Kits Fechados',
            fields: {
                category_id: 'Categoria',
                enterprise_id: 'Empresa',
                height_gte: 'Min height',
                height_lte: 'Max height',
                height: 'Height',
                image: 'Imagem',
                price: 'Preço',
                reference: 'Nome',
                sales: 'Vendas',
                stock_lte: 'Stock Baixo',
                stock: 'Stock',
                thumbnail: 'Thumbnail',
                width_gte: 'Min width',
                width_lte: 'Max width',
                width: 'Width',
            },
            tabs: {
                image: 'Imagem',
                details: 'Detalhes',
                description: 'Descrição',
                reviews: 'Reviews',
            },
            filters: {
                categories: 'Categorias',
                enterprises: 'Empresas',
                stock: 'Stock',
                no_stock: 'Fora de stock',
                low_stock: '1 - 9 itens',
                average_stock: '10 - 49 itens',
                enough_stock: '50 itens & mais',
                sales: 'Vendas',
                best_sellers: 'Mais vendidos',
                average_sellers: 'Vendas médias',
                low_sellers: 'Menos vendidos',
                never_sold: 'Nunca vendido',
            },
        },
        categories: {
            name: 'Categoria |||| Categorias',
            fields: {
                products: 'Produtos',
            },
        },
        enterprises: {
            name: 'Empresa |||| Empresas',
            fields: {
                products: 'Produtos',
            },
        },
        reviews: {
            name: 'Review |||| Reviews',
            amount: '1 review |||| %{smart_count} reviews',
            relative_to_poster: 'Review on poster',
            detail: 'Review detail',
            fields: {
                customer_id: 'Customer',
                command_id: 'Order',
                product_id: 'Product',
                date_gte: 'Posted since',
                date_lte: 'Posted before',
                date: 'Date',
                comment: 'Comment',
                rating: 'Rating',
            },
            action: {
                accept: 'Aceito',
                reject: 'Rejeitado',
            },
            notification: {
                approved_success: 'Review approved',
                approved_error: 'Error: Review not approved',
                rejected_success: 'Review rejected',
                rejected_error: 'Error: Review not rejected',
            },
        },
        segments: {
            name: 'Seguimento |||| Seguimentos',
            fields: {
                customers: 'Integradores',
                name: 'Nome',
            },
            data: {
                compulsive: 'Compulsive',
                collector: 'Collector',
                ordered_once: 'Ordered once',
                regular: 'Regular',
                returns: 'Returns',
                reviewer: 'Reviewer',
            },
        },
    },
};

export default customEnglishMessages;
