import OrderIcon from '@material-ui/icons/AttachMoney';
import OrderCreateIcon from '@material-ui/icons/PostAdd';

import OrderCreate from './OrderCreate';
import OrderList from './OrderList';
import OrderListOrdered from './OrderListOrdered';
import OrderEdit from './OrderEdit';

export default {
    create: OrderCreate,
    list: OrderList,
    listOrdered: OrderListOrdered,
    edit: OrderEdit,
    icon: OrderIcon,
    iconCreate: OrderCreateIcon,
};
