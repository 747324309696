import * as React from 'react';
import { FC, Fragment, useCallback, useEffect, useState } from 'react';
import {
    AutocompleteInput,
    BooleanField,
    Datagrid,
    DatagridProps,
    DateField,
    DateInput,
    Filter,
    FilterProps,
    Identifier,
    List,
    ListContextProvider,
    ListProps,
    NullableBooleanInput,
    NumberField,
    ReferenceInput,
    ReferenceField,
    SearchInput,
    TextField,
    TextInput,
    useGetList,
    useListContext,
    useCheckAuth,
} from 'react-admin';
import {
    useMediaQuery,
    Divider,
    Tabs,
    Tab,
    Theme,
    PropTypes,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import NbItemsField from './NbItemsField';
import CustomerReferenceField from '../visitors/CustomerReferenceField';
import FullNameField from '../visitors/FullNameField';
import AddressField from '../visitors/AddressField';
// import MobileGrid from './MobileGrid';
import { Customer } from '../types';
import { RouteComponentProps } from 'react-router-dom';

const OrderFilter: FC<Omit<FilterProps, 'children'>> = props => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        {/* <ReferenceInput source="customer_id" reference="customers">
            <AutocompleteInput
                optionText={(choice: Customer) =>
                    choice.id // the empty choice is { id: '' }
                        ? `${choice.first_name} ${choice.last_name}`
                        : ''
                }
            />
        </ReferenceInput> */}
        <DateInput source="date_gte" />
        <DateInput source="date_lte" />
        <TextInput source="total_gte" />
        <NullableBooleanInput source="returned" />
    </Filter>
);

const useDatagridStyles = makeStyles({
    total: { fontWeight: 'bold' },
});

const tabs = [
    { id: 'ordered', name: 'orçado' },
    { id: 'delivered', name: 'pedido' },
    { id: 'cancelled', name: 'cancelado' },
];

interface TabbedDatagridProps extends DatagridProps {
    location?: {} | undefined;
}

const useGetTotals = (filterValues: any) => {
    const username = localStorage.getItem('username');
    const { total: totalOrdered } = useGetList(
        'commands',
        { perPage: 1, page: 1 },
        { field: 'id', order: 'ASC' },
        { ...filterValues, status: 'ordered', owner: username }
    );
    const { total: totalDelivered } = useGetList(
        'commands',
        { perPage: 1, page: 1 },
        { field: 'id', order: 'ASC' },
        { ...filterValues, status: 'delivered', owner: username }
    );
    const { total: totalCancelled } = useGetList(
        'commands',
        { perPage: 1, page: 1 },
        { field: 'id', order: 'ASC' },
        { ...filterValues, status: 'cancelled', owner: username }
    );

    return {
        ordered: totalOrdered,
        delivered: totalDelivered,
        cancelled: totalCancelled,
    };
};

const TabbedDatagrid: FC<any> = props => {
    const listContext = useListContext();
    // @ts-ignore
    var statusLocation = props.location.state;
    const { ids, filterValues, setFilters, displayedFilters } = listContext;
    const classes = useDatagridStyles();
    const isXSmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('xs')
    );
    const [ordered, setOrdered] = useState<Identifier[]>([] as Identifier[]);
    const [delivered, setDelivered] = useState<Identifier[]>(
        [] as Identifier[]
    );
    const [cancelled, setCancelled] = useState<Identifier[]>(
        [] as Identifier[]
    );
    if (setFilters && statusLocation) {
        setFilters(
            { ...filterValues, status: statusLocation },
            displayedFilters
        );
    }
    const totals = useGetTotals(filterValues) as any;

    useEffect(() => {
        if (ids && ids !== filterValues.status) {
            switch (filterValues.status) {
                case 'draft':
                    setOrdered(ids);
                    break;
                case 'ordered':
                    setOrdered(ids);
                    break;
                case 'delivered':
                    setDelivered(ids);
                    break;
                case 'cancelled':
                    setCancelled(ids);
                    break;
            }
        }
    }, [ids, filterValues.status]);

    const handleChange = useCallback(
        (event: React.ChangeEvent<{}>, value: any) => {
            setFilters &&
                setFilters(
                    { ...filterValues, status: value },
                    displayedFilters
                );
        },
        [displayedFilters, filterValues, setFilters]
    );

    const selectedIds =
        filterValues.status === 'ordered'
            ? ordered
            : filterValues.status === 'delivered'
            ? delivered
            : cancelled;

    return (
        <Fragment>
            <Tabs
                variant="fullWidth"
                centered
                value={filterValues.status}
                indicatorColor="primary"
                onChange={handleChange}
            >
                {tabs.map(choice => (
                    <Tab
                        key={choice.id}
                        label={
                            totals[choice.name]
                                ? `${choice.name} (${totals[choice.name]})`
                                : choice.name
                        }
                        value={choice.id}
                    />
                ))}
            </Tabs>
            <Divider />
            {/* {isXSmall ? (
                <ListContextProvider
                    value={{ ...listContext, ids: selectedIds }}
                >
                    <MobileGrid {...props} ids={selectedIds} />
                </ListContextProvider>
            ) : ( */}
            <div>
                {filterValues.status === 'draft' && (
                    <ListContextProvider
                        value={{ ...listContext, ids: ordered }}
                    >
                        <Datagrid {...props} optimized rowClick="edit">
                            <DateField
                                label="Última atualização"
                                source="lastupdate"
                                showTime
                            />
                            <CustomerReferenceField />
                            <FullNameField label="Cliente" />
                            <ReferenceField
                                source="customer_id"
                                reference="customers"
                                link={false}
                                label="resources.commands.fields.address"
                            >
                                <AddressField />
                            </ReferenceField>
                            <NbItemsField />
                            <NumberField
                                source="total"
                                options={{
                                    style: 'currency',
                                    currency: 'BRL',
                                }}
                                className={classes.total}
                            />
                        </Datagrid>
                    </ListContextProvider>
                )}
                {filterValues.status === 'ordered' && (
                    <ListContextProvider
                        value={{ ...listContext, ids: ordered }}
                    >
                        <Datagrid {...props} optimized rowClick="edit">
                            <DateField
                                label="Data de criação"
                                source="createdate"
                                showTime
                            />
                            <DateField
                                label="Última atualização"
                                source="lastupdate"
                                showTime
                            />
                            <CustomerReferenceField />
                            <FullNameField label="Cliente" />
                            <AddressField label="resources.commands.fields.address" />
                            <NbItemsField />
                            <NumberField
                                source="total"
                                options={{
                                    style: 'currency',
                                    currency: 'BRL',
                                }}
                                className={classes.total}
                            />
                        </Datagrid>
                    </ListContextProvider>
                )}
                {filterValues.status === 'delivered' && (
                    <ListContextProvider
                        value={{ ...listContext, ids: delivered }}
                    >
                        <Datagrid {...props} rowClick="edit">
                            <DateField
                                label="Data de criação"
                                source="createdate"
                                showTime
                            />
                            <DateField
                                label="Última atualização"
                                source="lastupdate"
                                showTime
                            />
                            <CustomerReferenceField />
                            <FullNameField label="Cliente" />
                            <AddressField label="resources.commands.fields.address" />
                            <NbItemsField />
                            <NumberField
                                source="total"
                                options={{
                                    style: 'currency',
                                    currency: 'BRL',
                                }}
                                className={classes.total}
                            />
                            <BooleanField source="returned" />
                        </Datagrid>
                    </ListContextProvider>
                )}
                {filterValues.status === 'cancelled' && (
                    <ListContextProvider
                        value={{ ...listContext, ids: cancelled }}
                    >
                        <Datagrid {...props} rowClick="edit">
                            <DateField
                                label="Data de criação"
                                source="createdate"
                                showTime
                            />
                            <DateField
                                label="Última atualização"
                                source="lastupdate"
                                showTime
                            />
                            <CustomerReferenceField />
                            <FullNameField label="Cliente" />
                            <AddressField label="resources.commands.fields.address" />
                            <AddressField label="resources.commands.fields.address" />
                            <NbItemsField />
                            <NumberField
                                source="total"
                                options={{
                                    style: 'currency',
                                    currency: 'BRL',
                                }}
                                className={classes.total}
                            />
                            <BooleanField source="returned" />
                        </Datagrid>
                    </ListContextProvider>
                )}
            </div>
        </Fragment>
    );
};

const OrderList: FC<ListProps> = props => {
    // const checkAuth = useCheckAuth();
    // checkAuth().then(user => {
    //     // @ts-ignore
    //     var username = user.email;
    //     localStorage.setItem('username', username);
    //     // @ts-ignore
    //     var uid = user.uid;
    //     console.log('   UID: ' + uid);
    // });
    const username = localStorage.getItem('username');
    // console.log(props);
    // // @ts-ignore
    // console.log(props.location.pathname);
    // @ts-ignore
    console.log(props.location.state);
    return (
        <List
            {...props}
            filter={{ owner: username }}
            filterDefaultValues={{ status: 'ordered' }}
            sort={{ field: 'date', order: 'ASC' }}
            perPage={25}
            // filters={<OrderFilter />}
        >
            <TabbedDatagrid {...props} />
        </List>
    );
};

export default OrderList;
