import * as React from 'react';
import { FC, useCallback, useState } from 'react';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {
    Create,
    FormTab,
    NumberInput,
    ReferenceInput,
    SelectInput,
    TabbedForm,
    TextInput,
    TextField,
    required,
    CreateProps,
    ReferenceManyField,
    Datagrid,
    DateField,
    EditButton,
    Pagination,
    List,
    BulkActionProps,
    ArrayInput,
    SimpleFormIterator,
    ShowButton,
    ReferenceArrayInput,
    SelectArrayInput,
    NumberField,
    Link,
    FormDataConsumer,
    useRefresh,
    Identifier,
    useUnselectAll,
    Filter,
    useGetMany,
    useGetList,
    useListContext,
    ListContextProvider,
    useGetOne,
    useRedirect,
    useTranslate,
    DateInput,
    email,
    AutocompleteInput,
    TopToolbar,
    useNotify,
    Title,
    SimpleForm,
    Toolbar,
    SaveButton,
} from 'react-admin';

import LinkToRelatedCustomers from './LinkToRelatedCustomers';
import segments from './data';
import { useForm } from 'react-final-form';

const useStyles = makeStyles({
    root: {
        marginTop: 16,
    },
});
var irradiationAnualSelected: string = ' ';
var stateIrradiationSelected: String = ' ';
var powerRangeID: String = ' ';
const useGetStates = () => {
    const { data: totalStates } = useGetList(
        'state_irradiation',
        { perPage: 1000, page: 1 },
        { field: 'name', order: 'ASC' },
        {}
    );
    return totalStates;
};
const useGetIrradiation = () => {
    const { data: toltalGlobal } = useGetList(
        'global_irradiation',
        { perPage: 60000, page: 1 },
        { field: 'name', order: 'ASC' },
        {}
    );
    return toltalGlobal;
};
const FormControl = (event: { target: { name: any; value: any } }) => {
    const { name, value } = event.target;
    // const [stateData, setStateData] = useReducer(
    //     (state: any, newState: any) => ({ ...state, ...newState }),
    //     {
    //         categories_power_id: '-',
    //     }
    // );
    console.log(name);
    console.log(value);
    // setStateData({ [name]: value });
    // setStateData({ categories_power_id: '...' });
    // form.change('categories_power_id', sistemarecomendado);
    // var names = form.getState().values['categories_power_id_testec'];
};
const CalculeFieldsButton = ({ ...props }) => {
    const form = useForm();
    var formdata = form.getFieldState('address');
    var formdataValues = form.getState().values;
    const handleClick = useCallback(() => {
        var average_monthly_consumption = form.getState().values[
            'average_monthly_consumption'
        ];
        var generation_percentage = form.getState().values[
            'generation_percentage'
        ];
        var system_losses = form.getState().values['system_losses'];
        generation_percentage = generation_percentage / 100;
        system_losses = system_losses / 100;
        console.log(`system_losses: ${system_losses}`);
        console.log(`generation_percentage: ${generation_percentage}`);
        var irradion = parseInt(irradiationAnualSelected);
        // var average_monthly_consumption = 19.05833;
        // var irradion = 5.8;
        // var generation_percentage = 1.1;
        // var system_losses = 0.185;
        var operation0 = irradion / 1000;
        console.log(`operation0: ${operation0}`);
        var operation1 = average_monthly_consumption / operation0;
        console.log(`operation1: ${operation1}`);
        var operation2 = operation1 * 1000 * generation_percentage;
        console.log(`operation2: ${operation2}`);
        var losses = 1 - system_losses;
        console.log(`losses: ${losses}`);
        var power = operation2 / losses;
        var powerk = power / 1000;
        console.log(`Power do sistema: ${power}`);
        form.change('recommended_system', powerk);
        form.change('power', powerk);
    }, [form]);

    return (
        <Button onClick={handleClick} {...props}>
            {' '}
            Calcular
        </Button>
    );
};
const UserCreateToolbar = (props: any) => <Toolbar {...props}>{null}</Toolbar>;
const CalculatorShow: FC = () => {
    const translate = useTranslate();
    const classes = useStyles();
    const [irradiationAnual, setIrradiationAnual] = useState('5776');
    const [state_irradiation_select, setStateIrradiation] = useState('CEARÁ');
    const states_irradiation = useGetStates();
    const global_irradiation = useGetIrradiation();
    const onStateChange = <P extends keyof string>(value: string) => {
        console.log(`Novo esdado: ${value}`);
        // console.log(`Novo esdado: ${states_irradiation}`);
        // @ts-ignore
        var state_irradiation_select_op = states_irradiation[value];
        console.log(`Novo esdado: ${state_irradiation_select_op.name}`);
        // @ts-ignore
        setStateIrradiation(state_irradiation_select_op.name);
        stateIrradiationSelected = state_irradiation_select_op.name;
    };
    const onIrradiationChange = <P extends keyof string>(value: string) => {
        console.log(`Novo irradiation: ${value}`);
        // @ts-ignore
        var irradiation_annual_select = global_irradiation[value].ANNUAL;
        setIrradiationAnual(irradiation_annual_select);
        irradiationAnualSelected = irradiation_annual_select;
    };
    return (
        <Card className={classes.root}>
            <Title
                title={translate('resources.segments.name', { smart_count: 2 })}
            />
            <SimpleForm toolbar={<UserCreateToolbar />}>
                <ReferenceInput
                    label="Esdado"
                    source="name"
                    sort={{ field: 'name', order: 'ASC' }}
                    reference="state_irradiation"
                    onChange={(e: { target: { value: string } }) => {
                        onStateChange(e.target.value);
                    }}
                    validate={required()}
                >
                    <SelectInput optionText="name" source="name" />
                </ReferenceInput>
                <ReferenceInput
                    label="Cidade"
                    source="global_irradiation_annual"
                    sort={{ field: 'NAME', order: 'ASC' }}
                    reference="global_irradiation"
                    onChange={(e: { target: { value: string } }) => {
                        onIrradiationChange(e.target.value);
                    }}
                    filter={{ STATE: state_irradiation_select }}
                    perPage={1000}
                >
                    <SelectInput optionText="NAME" source="NAME" />
                </ReferenceInput>
                <TextInput
                    label="Média de consumo mensal (kWh)   "
                    source="average_monthly_consumption"
                    validate={required()}
                />
                <TextInput
                    label="Percentual de Geração (%) "
                    source="generation_percentage"
                    validate={required()}
                />
                <TextInput
                    label="Perdas do Sistema (%) "
                    source="system_losses"
                    type="number"
                    validate={required()}
                />
                <CalculeFieldsButton label="Calcular" />
                <TextInput
                    label="Sistema indicado "
                    source="recommended_system"
                    onChange={FormControl}
                />
            </SimpleForm>
        </Card>
    );
};

export default CalculatorShow;
