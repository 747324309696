import * as React from 'react';
import { FC } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import Poster from './Poster';
import {
    Show,
    SimpleShowLayout,
    TextField,
    RichTextField,
    DateField,
} from 'react-admin';
import { Product } from '../types';

const useStyles = makeStyles({
    root: { display: 'inline-block', marginTop: '1em', zIndex: 2 },
    content: { padding: 0, '&:last-child': { padding: 0 } },
    img: {
        width: '42em',
        minWidth: '42em',
        maxWidth: '42em',
        maxHeight: '15em',
    },
});
const PostTitle = (props: any) => {
    return <span>{props.record ? `${props.record.reference}` : ''}</span>;
};
export const PosterShow = (props: any) => (
    <Show title={<PostTitle />} actions={false} {...props}>
        <SimpleShowLayout>
            <Poster />
            <TextField source="reference" />
            <TextField source="price" />
            <RichTextField label="Detalhes" source="description" />
        </SimpleShowLayout>
    </Show>
);
export default PosterShow;
