import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core/styles';

import IconImageEye from '@material-ui/icons/RemoveRedEye';
import IconKeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import {
    Button,
    SimpleShowLayout,
    TextField,
    RichTextField,
} from 'react-admin';
import Poster from '../products/Poster';
import { makeStyles } from '@material-ui/core/styles';

const styles = {
    field: {
        // These styles will ensure our drawer don't fully cover our
        // application when teaser or title are very long
        '& span': {
            display: 'inline-block',
            maxWidth: '200em',
        },
    },
    painel: {
        '& span': {
            display: 'inline-block',
            maxWidth: '200em',
        },
    },
};
const useStyles = makeStyles(styles);

const PostPreviewView = ({ ...props }) => {
    console.log(props);
    const classes = useStyles();
    return (
        <SimpleShowLayout {...props}>
            <Poster />
            <TextField source="reference" />
            <TextField source="price" />
            <RichTextField label="Detalhes" source="description" />
        </SimpleShowLayout>
    );
};
/* {<TextField source="reference" className={classes.painel} />
<TextField source="price" className={classes.painel} />
<TextField source="potence" className={classes.painel} />
<TextField source="status" className={classes.painel} /> } */
// const PostPreviewView = ({ ...props }) => (
//     <SimpleShowLayout {...props}>
//         <ThumbnailField />
//         <TextField source="reference" />
//         <TextField source="price" />
//         <TextField source="potence" />
//         <TextField source="status" />
//     </SimpleShowLayout>
// );

const mapStateToProps = (state: any, props: any) => ({
    // Get the record by its id from the react-admin state.
    record: state.admin.resources[props.resource]
        ? state.admin.resources[props.resource].data[props.id]
        : null,
    version: state.admin.ui.viewVersion,
});

const PostPreview = connect(
    mapStateToProps,
    {}
)(withStyles(styles)(PostPreviewView));

class PreviewProductButton extends Component {
    state = { showPanel: false };
    handleClick = () => {
        this.setState({ showPanel: true });
    };

    handleCloseClick = () => {
        this.setState({ showPanel: false });
    };
    render() {
        const { showPanel } = this.state;
        // const { id } = this.props;
        console.log('button');
        console.log(this.props);
        return (
            <Fragment>
                <Button onClick={this.handleClick} label="Visualizar">
                    <IconImageEye />
                </Button>
                <Drawer
                    anchor="right"
                    open={showPanel}
                    onClose={this.handleCloseClick}
                >
                    <div>
                        <Button label="Fechar" onClick={this.handleCloseClick}>
                            <IconKeyboardArrowRight />
                        </Button>
                    </div>
                    <PostPreviewView {...this.props} />
                    {/* <PostPreview
                        id="id"
                        basePath="/products"
                        resource="products"
                    /> */}
                </Drawer>
            </Fragment>
        );
    }
}

export default connect()(PreviewProductButton);
